import type { AxiosInstance } from "axios";
import { APIErrorResponse, API_ERROR_RESPONSE } from "./types/api-error";
import {
    StateLookupRequest,
    StateLookupResponse,
    STATE_LOOKUP_RESPONSE,
} from "./types/state-lookup-model";
import axios from 'axios';
import { SaveQuoteRequest, SaveQuoteResponse, SAVE_QUOTE_RESPONSE } from "./types";
import { ClientConfigResponse, CLIENT_CONFIG_RESPONSE } from "./types/config-response";
import { BusinessKeywordResponse, BUSINESS_KEYWORD_RESPONSE } from "./types/business-keyword-model";
import { CarrierRecommendationResponse, CARRIER_RECOMMENDATION_RESPONSE, MultipleCarrierRecommendationResponse, MULTIPLE_CARRIER_RECOMMENDATION_RESPONSE } from "./types/carrier-recommendation-model";
import { LoggingResourceModel } from "./types/quote-request";
export const BASE_APP_PAth = "/business-insurance";
export const BASE_URL = window.location.origin + BASE_APP_PAth;
export const API_ERROR_MESSAGE = "There was a problem while processing the information you submitted. "
    + "Please call an (866)509-9444 and an insurance counselor can assist you with completing your quote or setting up your policy.";


// Set the name of the cookie to send as CSRF token
axios.defaults.xsrfCookieName = '_h_lpd_af';

// Set the name of the header to send as CSRF token
axios.defaults.xsrfHeaderName = 'x-lpd-af';

export const launchPadAPI = (api: AxiosInstance) => ({
    commercialAgentApi: {
        getClientConfig: (): Promise<ClientConfigResponse | APIErrorResponse> =>
            api.get(BASE_URL + "/api/clientconfigurations")
                .then(({ data }) => {
                    return {
                        data: data,
                        type: CLIENT_CONFIG_RESPONSE,
                    };
                },
                    ({ response }) => ({
                        ...(response || { data: {} }).data,
                        type: API_ERROR_RESPONSE,
                    })
                ),
        saveQuote: (saveQuoteRequest: SaveQuoteRequest, isComplete: boolean = false): Promise<SaveQuoteResponse | APIErrorResponse> =>
            api
                .post(
                    BASE_URL + (isComplete ? "/api/webquote/complete" : "/api/webquote"),
                    JSON.stringify(saveQuoteRequest),
                    { headers: { "Content-type": "application/json" } }
                )
                .then(({ data }) => {
                    return {
                        data: data,
                        type: SAVE_QUOTE_RESPONSE,
                    };
                },
                    ({ response }) => ({
                        ...(response || { data: {} }).data,
                        type: API_ERROR_RESPONSE,
                    })
                ),
        getBusinessKeywords: (lobcode: string): Promise<BusinessKeywordResponse | APIErrorResponse> =>
            api.get(BASE_URL + "/api/lookup/businesskeywords?lobcode=" + lobcode)
                .then(({ data }) => {
                    return {
                        data: data,
                        type: BUSINESS_KEYWORD_RESPONSE,
                    };
                },
                    ({ response }) => ({
                        ...(response || { data: {} }).data,
                        type: API_ERROR_RESPONSE,
                    }
                    )
                ),
        getCarrierRecommendation: (saveQuoteRequest: SaveQuoteRequest): Promise<CarrierRecommendationResponse | APIErrorResponse> =>
            api
                .post(BASE_URL + "/api/lookup/GetCarrierRecommendation",
                    JSON.stringify(saveQuoteRequest),
                    {
                        headers:
                            { "Content-type": "application/json" }
                    })
                .then(({ data }) => {
                    return {
                        data: data,
                        type: CARRIER_RECOMMENDATION_RESPONSE,
                    };
                },
                    ({ response }) => ({
                        ...(response || { data: {} }).data,
                        type: API_ERROR_RESPONSE,
                    }
                    )
                ),
        stateLookup: (
            commercialAgentApiStateLookupRequest: StateLookupRequest
        ): Promise<StateLookupResponse | APIErrorResponse> =>
            api
                .get(BASE_URL + "/api/lookup/StateFromZipCode?zipCode=" +
                    commercialAgentApiStateLookupRequest.zipCode)
                .then(
                    ({ data }) => {
                        return {
                            data: data,
                            type: STATE_LOOKUP_RESPONSE,
                        };
                    },
                    ({ response }) => ({
                        ...(response || { data: {} }).data,
                        type: API_ERROR_RESPONSE,
                    })
                ),
        logError: (logRequest: LoggingResourceModel): Promise<string> =>
            api
                .post(BASE_URL + "/api/ErrorLog/LogError",
                    JSON.stringify(logRequest),
                    {
                        headers:
                            { "Content-type": "application/json" }
                    })
                .then((response) => {
                    console.log(response);
                    return "Success";
                }, (error) => {
                    console.log(error);
                    return "Error";
                }),
        getMultipleCarrierRecommendation: (saveQuoteRequest: SaveQuoteRequest): Promise<MultipleCarrierRecommendationResponse | APIErrorResponse> =>
            api
                .post(BASE_URL + "/api/lookup/GetMultipleCarrierRecommendation",
                    JSON.stringify(saveQuoteRequest),
                    {
                        headers:
                            { "Content-type": "application/json" }
                    })
                .then(({ data }) => {
                    return {
                        data: data,
                        type: MULTIPLE_CARRIER_RECOMMENDATION_RESPONSE,
                    };
                },
                    ({ response }) => ({
                        ...(response || { data: {} }).data,
                        type: API_ERROR_RESPONSE,
                    })
                ),
        getBusinessKeywordRecommendation: (keyword: string): Promise<BusinessKeywordResponse | APIErrorResponse> =>
            api.get(BASE_URL + "/api/lookup/GetBusinessKeywordRecommendation?keyword=" + keyword)
                .then(({ data }) => {
                    return {
                        data: data,
                        type: BUSINESS_KEYWORD_RESPONSE,
                    };
                },
                    ({ response }) => ({
                        ...(response || { data: {} }).data,
                        type: API_ERROR_RESPONSE,
                    })
                )
    }
});
